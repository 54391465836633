@use "src/assets/scss/abstracts/mixins" as m;
@use "src/assets/scss/abstracts/variables" as v;

.custom-radio-button {
  position: relative;
  z-index: 1;
  @include m.centerLineWithFlex;
  min-height: 1.5rem;
  padding-left: 2.5rem;
  print-color-adjust: exact;
  &__input {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  &__label {
    position: relative;
    margin-bottom: 0;
    @include m.media("second") {
      font-size: 0.75rem;
    }
    &::after, &::before {
      content: "";
      position: absolute;
      cursor: pointer;
      left: -2.5rem;
      display: block;
      border-radius: 50%;
    }
    &::before {
      border: 2px solid v.$lighter-gray-color;
      pointer-events: none;
      transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      border-radius: 50%;
      @include m.box(1.5rem);
      background-color: v.$white-color;
      top: 0;
    }
    &::after {
      @include m.box(0.9rem);
      background-color: v.$white-color;
      top: 0.3rem;
      left: -2.2rem;
    }
  }
  &__input:checked ~ &__label::after {
    background-color: v.$primary-green-color;
  }
}